import { findSuffix, TYPE_STRING, TYPE_INT, TYPE_FLOAT, TYPES, valueMatchWithType, castValue } from "./customEventsHelper"
import { map } from "../../helpers/objectHelper"

export const MAX_EVENT_NAME_LENGTH = 256
export const EVENT_PROPERTIES_PER_BUNDLE = 50
export const MAX_PROPERTY_NAME_LENGTH = 256
export const MAX_STRING_PROPERTY_VALUE_LENGTH = 256

export function maxEventNameCleanup(name) {
    return name.slice(0, MAX_EVENT_NAME_LENGTH)
}

function maxPropertyNameCleanup(name) {
    return name.slice(0, MAX_PROPERTY_NAME_LENGTH)
}

function maxPropertyValueCleanup(value, type) {
    if (type === TYPE_STRING) {
        return value.slice(0, MAX_STRING_PROPERTY_VALUE_LENGTH)
    }

    return value
}

function valueKey(type, value) {
    let t = type

    switch (type) {
        case TYPE_INT:
            if (value > Number.MAX_SAFE_INTEGER) {
                t = TYPE_FLOAT
            }
            break;
        default:
            break;
    }

    return `value_${t}`
}

export function mapEventProps(properties) {
    properties = properties || {}
    const errors = []

    const props = map(properties, (_, key) => {
        const value = properties[key]
        let isManualCastedType = ""
        let manualCastedName = ""
        let manualCastedType = ""

        TYPES.forEach((type) => {
            if (isManualCastedType) {
                return
            }

            const suffix = `_${type}`

            const [condition, startIndex] = findSuffix(suffix, key)

            isManualCastedType = condition
            manualCastedName = key.slice(0, startIndex)
            manualCastedType = type
        })

        if (isManualCastedType) {
            const matchType = valueMatchWithType(value, manualCastedType)

            if (!matchType) {
                errors.push(`${JSON.stringify(value)} is not typeof ${manualCastedType}`)

                return
            }

            return {
                name: maxPropertyNameCleanup(manualCastedName),
                [valueKey(manualCastedType, value)]: maxPropertyValueCleanup(value, manualCastedType),
            }
        }

        const [castedValue, type] = castValue(value)

        if (!type) {
            return
        }

        return {
            name: maxPropertyNameCleanup(key),
            [valueKey(type, castedValue)]: maxPropertyValueCleanup(castedValue, type),
        }
    }).filter((props) => props && !!props.name)

    if (props.length > EVENT_PROPERTIES_PER_BUNDLE) {
        return [null, "max custom properties exceeded"]
    }

    return [props, errors.length ? errors : null]
}
