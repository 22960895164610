import { safe } from "./../common/tools"
import { Ticker } from "../common/ticker"
import { events } from "./events/typedef"
import forEach from "for-each"

class InputWatcher {
    constructor({ utils, eventsStream, aquireInterval, setInterval, options, globalEvents }) {
        this.utils = utils
        this.options = options
        this.eventsStream = eventsStream

        this.values = {}
        this.inputs = {}

        this.ticker = new Ticker({ interval: aquireInterval })
        globalEvents.on(`handlers.${events.MUTATION}.inited`, (rootID, children, mirror) => {
            this.mirror = mirror
            if (!this.registered) this.Register()
        })

        this.fallback = false
        this.registered = false
    }

    unregisterInput(node, id) {
        if (this.inputs[id]) delete this.inputs[id]
        if (node) {
            node.removeEventListener("change", this.ChangeHandler.bind(this), true)
            node.removeEventListener("input", this.ChangeHandler.bind(this), true)
            node.removeEventListener("blur", this.SetHandler.bind(this), true)
            node._lsWatched = false
        }
    }

    registerInput(node, id) {
        this.inputs[id] = node
        if (node._lsWatched) return
        node.addEventListener("change", this.ChangeHandler.bind(this), true)
        node.addEventListener("input", this.ChangeHandler.bind(this), true)
        node.addEventListener("blur", this.SetHandler.bind(this), true)
        node._lsWatched = true
    }

    watchInputNode(node) {
        const lsID = this.mirror.knownNodes.get(node)
        if (lsID) this.registerInput(node, lsID)
    }

    watchProperty(proto, property) {
        const cfg = Object.getOwnPropertyDescriptor(proto, property)
        const _this = this
        if (cfg) {
            Object.defineProperty(proto, property, {
                set: function (val) {
                    safe(() => {
                        _this.setNodeValue(this, val)
                    })
                    return cfg.set.call(this, val)
                },
                get: function () {
                    return cfg.get && cfg.get.call(this)
                },
            })
        }
    }

    Register() {
        const cfg = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")
        if (cfg && cfg.set) {
            this.watchProperty(HTMLInputElement.prototype, "value")
            this.watchProperty(HTMLInputElement.prototype, "checked")
            this.watchProperty(HTMLSelectElement.prototype, "value")
            this.watchProperty(HTMLTextAreaElement.prototype, "value")

            // not working, but maybe is okay (?)
            // const inputs = document.querySelectorAll("input")
            // inputs.forEach((n) => {
            //     // this.watchProperty(Object.getPrototypeOf(n), "value")
            //     // n = Object.setPrototypeOf(n, HTMLInputElement.prototype)
            //  })
        } else {
            this.fallback = true
        }
        this.ticker.Start(this.handleInterval.bind(this))
        this.registered = true
    }

    ChangeHandler(e) {
        this.setNodeValue(e.target)
    }

    SetHandler(e) {
        const node = e.target
        if (this.canRecordValues(node)) {
            const lsID = this.mirror.knownNodes.get(node)
            const filteredValue = this.getElementValue(node)
            if (node._lsLastValue === filteredValue) return
            this.eventsStream.Add(events.VALUE_SET, {
                value: filteredValue,
                path: this.utils.DOM.FullPath(node),
                el: this.utils.DOM.ElementName(node),
                json_data: { lsid: lsID },
            })
            node._lsLastValue = filteredValue
        }
    }

    setNodeValue(node, value) {
        const filteredValue = this.getElementValue(node, value)
        if (filteredValue === null) {
            return
        }
        const lsID = this.mirror.knownNodes.get(node)
        if (lsID)
            this.values[lsID] = {
                value: filteredValue,
                path: this.utils.DOM.FullPath(node),
                el: this.utils.DOM.ElementName(node),
                json_data: { lsid: lsID },
            }
    }

    handleInterval() {
        forEach(this.values, (v, i) => {
            this.eventsStream.Add(events.VALUE_CHANGE, v)
        })
        this.values = {}
    }

    canRecordValues(node) {
        if (node.getAttribute("data-ls-disabled") != null) {
            return false
        }
        if (node.getAttribute("data-ls-enabled") != null) {
            return true
        }
        return this.options.Get("keystrokes")
    }

    getElementValue(node, value) {
        const t = node.getAttribute("type")
        const isEnabled = this.canRecordValues(node)
        const autocomplete = node.getAttribute("autocomplete")
        const nodeValue = value == null || typeof value === "undefined" ? node.value : value

        if (
            [
                "cc-additional-name",
                "cc-csc",
                "cc-exp-month",
                "cc-exp-year",
                "cc-exp",
                "cc-family-name",
                "cc-given-name",
                "cc-name",
                "cc-number",
                "cc-type",
            ].indexOf(autocomplete) > -1
        ) {
            return null
        }

        if (autocomplete && autocomplete.indexOf("cc-") === 0) {
            return null
        }

        if (t) {
            switch (t.toLowerCase()) {
                case "checkbox":
                case "radio":
                    return (!(value == null && typeof value === "undefined") ? !!value + 0 : node.checked + 0) + ""
                    break
                case "number":
                case "date":
                case "datetime-local":
                case "time":
                case "week":
                case "month":
                case "range":
                    return isEnabled ? nodeValue : null
                case "file":
                case "hidden":
                case "password":
                    return null
                case "submit":
                    return nodeValue
                default:
                    return isEnabled ? nodeValue : this.utils.StarifyString(nodeValue)
            }
        }
        return isEnabled ? nodeValue : this.utils.StarifyString(nodeValue)
    }
}

export { InputWatcher }
